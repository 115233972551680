import React, { useState } from "react";
import type { InitialDashboardLikesValues } from "../machines/dashboard-likes-machine";
import { useDashboardLikes } from "../machines/dashboard-likes-machine";
import LikeButton from "./like-button";
import { useCurrentUser } from "~/features/current-user";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";

interface DashboardLikesProps extends InitialDashboardLikesValues {
  variant: "published" | "discover";
}

export interface DashboardLikesButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  likedByMe: boolean;
  likeCount?: number | null;
  variant: "published" | "discover";
}

export const DashboardLikes = ({ initiallyLikedByMe, initialLikeCount, dashboardId, variant }: DashboardLikesProps) => {
  const { currentUser } = useCurrentUser();
  const { likedByMe, likeCount, toggleLiked } = useDashboardLikes({
    initiallyLikedByMe,
    initialLikeCount,
    dashboardId,
  });

  const [openAuthModal, setOpenAuthModal] = useState(false);

  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };

  return (
    <>
      <LikeButton
        onClick={(e) => {
          if (currentUser) {
            toggleLiked();
          } else {
            setOpenAuthModal(true);
          }
        }}
        likedByMe={likedByMe}
        likeCount={likeCount}
        variant={variant}
      />
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-like"
      />
    </>
  );
};
