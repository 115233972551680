import { Button } from "@fscrypto/flip-kit/button";
import clsx from "clsx";
import { HeartIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";
import type { DashboardLikesButtonProps } from "./dashboard-likes";

const LikeButton = ({ onClick, likeCount, likedByMe, variant }: DashboardLikesButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    tracking(`click_like_dashboard`, "Dashboard Editor");
    onClick(e);
  };
  return (
    <Button
      onClick={handleClick}
      variant="ghost"
      data-testid="like-button"
      aria-label="like-button"
      className="text-current cursor-pointer p-0 hover:bg-transparent space-x-1"
    >
      <HeartIcon
        className={clsx("size-4", {
          "fill-red-50 text-red-50": likedByMe,
          "text-contrast/50": !likedByMe,
        })}
      />
      <span>{(likeCount ?? 0) > 0 ? likeCount : 0}</span>
    </Button>
  );
};

export default LikeButton;
